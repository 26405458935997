
import { useRef,useState } from "react";
import axios from "axios";
import Header from "../layouts/Header";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  FormGroup,
  Checkbox
} from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import emailjs from "@emailjs/browser";

  export const Form = () => {
    const form = useRef();
    const [formStatus, setFormStatus] = useState(false);
    // const [selected, setSelected] = useState([]);
    // const [email, setEmail] = useState("");
    const [prefecture, setPrefecture] = useState("");
    const [city, setCity] = useState("");
    const [town, setTown] = useState("");

    const handleChange = async (e) => {
      const res = await axios.get('https://api.zipaddress.net/?zipcode=' + e.target.value);
      if(res.data.code === 200) {
        setPrefecture(res.data.data.pref);
        setCity(res.data.data.city);
        setTown(res.data.data.town);
      }
    }

  const serviceId = process.env.REACT_APP_SERVICEID;
  const templateId = process.env.REACT_APP_TEMPLATEID;
  const publicId = process.env.REACT_APP_PUBLIC;

    const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(serviceId, templateId, form.current, publicId)
      .then((result) => {
        console.log(result.text);
        setFormStatus(true);
       
        window.location.href = "https://form-thanks.porte-co.jp/";
      
      })
      .catch((err) => console.log(err.text));
  };

    return (
      <div className="">
        <div>
          <Header />
        </div>
        <br/><br/><br/><br/><br/>


      <div className="formblock-part">

        <div className="form-title form-wrapper">
            <p className="form-wrapper-title">
             無料お見積もり訪問のご依頼
              <div className="under-line"></div>
              <div className="tel-info">やっぱりお電話での見積もりなら</div>
              <a href="tel:0120949383" className="tel-a-tag btn_tell form_telBtn">
                <div className="form-telBtn"></div>
              </a>
            </p>
        </div>

        <div className="form-wra-txt">
           ↓お見積もり訪問のご予約情報のご入力お願いします</div>
        <p>* は必須項目です。</p>

        <div class="estimate-part">
        <form ref={form} onSubmit={sendEmail}>
          <TextField
            required
            id="standard-required"
            label="お名前（漢字）"
            name="name-kanji"
            type="text"
            variant="standard"
            className="input-sm"
            fullWidth
          />
          <br />
          <br />
          <TextField
            required
            id="standard-required"
            label="お名前（ふりがな）"
            name="name"
            type="text"
            variant="standard"
            className="input-sm"
            fullWidth
          />
          <br />
          <br />
          <TextField
            required
            id="standard-required"
            label="メールアドレス"
            name="email"
            type="email"
            placeholder="example@email.com"
            variant="standard"
            className="input-sm"
            fullWidth
           />
          <br />
          <br />
          <TextField
            required
            id="standard-required"
            label="電話番号  ※ハイフンを入れてください"
            type="text"
            name="phone"
            placeholder="例）000-0000-0000"
            variant="standard"
            className="input-sm"
            fullWidth
          />
          <br />
          <br />
            <div className="">
                <label htmlFor="zipCode" className="form-label form-label-p">郵便番号　</label>
                <input type="text" name="area-num"className="form-control post-input-2" onChange={handleChange}/>
            </div>
            <br />
                <div className="col-md-4 col-4">
                  <label htmlFor="prefecture" className="form-label form-label-p">都道府県　</label>
                  <input type="text" name="address1"className="form-control post-input" value={prefecture} disabled={false} />
                  <label htmlFor="city"  className="form-label form-label-p">　市町村　</label>
                  <input type="text" name="address2" className="form-control post-input" value={city} disabled={false} /><span className="sp"><br/></span>
                  <label htmlFor="town" className="form-label form-label-p">　町名　</label>
                  <input type="text" name="address3" className="form-control post-input" value={town} disabled={false} />
                </div>
                <br />
                <TextField
              required
               id="standard-basic"
               label="ご住所の続き（番地〜建物名・部屋番号をご記載ください）"
               type="text"
               name="address4"
               placeholder=""
               variant="standard"
               className="input-sm"
               fullWidth
             />
              <br />
              <br />
              <FormControl>
               <RadioGroup
                 row
                 aria-labelledby="demo-row-radio-buttons-group-label"
                 name="area-opt"
               >
                 <FormControlLabel
                   value="EVあり"
                   control={<Radio />}
                   label="エレベーターあり"
                 />
                 <FormControlLabel
                   value="EVなし"
                   control={<Radio />}
                   label="エレベーターなし"
                 />
                 <FormControlLabel
                   value="戸建て"
                   control={<Radio />}
                   label="戸建て"
                 />
               </RadioGroup>
             </FormControl>
             <br />
             <br />
             <div class="group-bg">
             <label className="label-txt-left">回収するもの（複数選択可）*</label>
             <p>下記以外にお荷物がある場合は「その他のお荷物詳細」にご記入ください。</p>
             <FormGroup>
              <ul class="checkbox-ul">
                <li>
                <FormControlLabel control={<Checkbox />} name="collect" value="テレビ" label="テレビ" />
                <FormControlLabel control={<Checkbox />} name="collect" value="冷蔵庫" label="冷蔵庫" />
                <FormControlLabel control={<Checkbox />} name="collect" value="洗濯機" label="洗濯機" />
                <FormControlLabel control={<Checkbox />} name="collect" value="エアコン" label="エアコン" />
                </li>
                <li>
                <FormControlLabel control={<Checkbox />} name="collect" value="ベッド" label="ベッド" />
                <FormControlLabel control={<Checkbox />} name="collect" value="ソファー" label="ソファー" />
                <FormControlLabel control={<Checkbox />} name="collect" value="棚類" label="棚類" />
                <FormControlLabel control={<Checkbox />} name="collect" value="テーブル" label="テーブル" />
                </li>
                <li>
                <FormControlLabel control={<Checkbox />} name="collect" value="チェア" label="チェア" />
                <FormControlLabel control={<Checkbox />} name="collect" value="衣装ケース" label="衣装ケース" />
                <FormControlLabel control={<Checkbox />} name="collect" value="カーペット" label="カーペット" />
                <FormControlLabel control={<Checkbox />} name="collect" value="照明類" label="照明類" />
                </li>
                </ul>
              </FormGroup>
              </div>
           
               <TextField
               id="standard-multiline-static"
               label="その他のお荷物詳細"
               multiline
               rows={3}
               name="else-collect"
               defaultValue=""
               placeholder="上記以外でお荷物がある方は、詳細をご記入ください。"
               variant="standard"
               className="input-sm"
               fullWidth
             />
             <br />
             <br />
             <p className="formblock-date">
               見積もり訪問の希望日（第一希望）（必須）
             </p>
             <TextField
               required
               id="standard-basic"
               label=""
               type="date"
               placeholder=""
               name="date1"
               variant="standard"
               className="input-sm"
               fullWidth
             />
<br />
             <br />
             <FormControl variant="standard" className="input-sm" fullWidth>
               <InputLabel id="demo-simple-select-standard-label">
                 ご希望の時間
               </InputLabel>
               <Select
                 labelId="demo-simple-select-standard-label"
                 id="demo-simple-select-standard"
                 name="time1"
                 label="ご希望の時間"
               >
                 <MenuItem value="9-12時">9:00〜12:00</MenuItem>
                 <MenuItem value="12-15時">12:00〜15:00</MenuItem>
                 <MenuItem value="15-18時">15:00〜18:00</MenuItem>
                 <MenuItem value="18-21時">18:00〜21:00</MenuItem>
               </Select>
             </FormControl>
             <br />
             <br />
             <p className="formblock-date">見積もり訪問の希望日（第二希望）</p>
             <TextField
               id="standard-basic"
               label=""
               type="date"
               placeholder=""
               name="date2"
               variant="standard"
               className="input-sm"
               fullWidth
             />
             <br />
             <br />
             <FormControl variant="standard" className="input-sm" fullWidth>
               <InputLabel id="demo-simple-select-standard-label">
                 ご希望の時間
               </InputLabel>
               <Select
                 labelId="demo-simple-select-standard-label"
                 id="demo-simple-select-standard"
                 name="time2"
                 label="ご希望の時間"
               >
                 <MenuItem value="9-12時">9:00〜12:00</MenuItem>
                 <MenuItem value="12-15時">12:00〜15:00</MenuItem>
                 <MenuItem value="15-18時">15:00〜18:00</MenuItem>
                 <MenuItem value="18-21時">18:00〜21:00</MenuItem>
               </Select>
             </FormControl>
             <br />
             <br />
             <FormControl>
               <FormLabel id="demo-row-radio-buttons-group-label">
                 見積もり後、当日回収を希望されますか？
               </FormLabel>
               <RadioGroup
                 row
                 aria-labelledby="demo-row-radio-buttons-group-label"
                 name="ontheday"
               >
                 <FormControlLabel
                   value="金額次第で回収希望"
                   control={<Radio />}
                   label="希望する"
                 />
                 <FormControlLabel
                   value="見積もりのみ"
                   control={<Radio />}
                   label="希望しない"
                 />
               </RadioGroup>
             </FormControl>
             <TextField
               id="standard-multiline-static"
               label="その他お問い合わせ（任意）"
               multiline
               rows={3}
               name="message"
               defaultValue=""
               placeholder="まずは見積もりしてからお願いするか決めたい。など"
               variant="standard"
               className="input-sm"
               fullWidth
             />
             <br></br>
             <br />
             <br />
             <br />
             <input type="hidden" name="_recaptcha" id="_recaptcha" />
             <button type="submit" className="form-send-btn">
               送信する
             </button>
        </form>
        </div>{/* estimate-part */}
        <br />
         {formStatus ? (
           <div className="alert alert-success">送信しています。</div>
         ) : (
           ""
         )}
         <br />
         <br />
         <div className="illus-porte"></div>
         <br />
         <p className="copyrights">©︎PORTE, Inc. Allrigths Reserved. 2022</p>
         <br />
        </div>{/* formblock-part*/}
      </div>
    )
  }

  export default Form