import React from "react";
import { elastic as Menu } from "react-burger-menu";

function Header(props) {
  return (
    
    <header>
    <div class="head-logo">
    <a href="https://porte-co.jp/">
    <div class="head-logo-img"></div>
    </a>
    </div>
    <div class="head-banner">
    <ul class="head-inner">
    <li class="head-tel"></li>
    <li class="head-form">
    <a href="https://form.porte-co.jp">
    <div class="head-form-img"></div>
    </a>
    </li>
    <li class="head-line">
    <a href="https://liff-gateway.lineml.jp/landing?follow=%40404buebt&lp=cUIi4r&liff_id=2000042640-wpEebM7Z">
    <div class="head-line-img"></div>
    </a>
    </li>
    </ul>
    </div>
    <div className="menu-bar">
        <Menu right>
    
          <a
           href="https://porte-co.jp/service"
             rel="サービス"
             className="menu-item"
          >
            サービス
          </a>
          <a
            href="https://porte-co.jp/price"
            rel="料金プラン"
            className="menu-item"
          >
            料金プラン
          </a>
          <a
            href="https://porte-co.jp/allarea"
            rel="対応エリア"
            className="menu-item"
          >
            対応エリア
          </a>
          <a
            href="https://porte-co.jp/voice"
            rel="回収の事例"
            className="menu-item"
          >
            回収の事例
          </a>
          <a
            href="https://porte-co.jp/voc"
            rel="お客様の声"
            className="menu-item"
          >
            お客様の声
          </a>
          <a href="https://form.porte-co.jp" className="menu-item">
            見積もり依頼
          </a>
        </Menu>
      </div>
    <div class="gnavi__wrap">
    <ul class="gnavi__lists">
    <li class="gnavi__list"><a href="https://porte-co.jp/reason/">業界最安値の理由</a></li>
    <li class="gnavi__list">
    <a href="/">サービス ▶︎</a>
    <ul class="dropdown__lists">
    <li class="dropdown__list"><a href="https://porte-co.jp/service/">サービスの概要</a></li>
    <li class="dropdown__list"><a href="https://porte-co.jp/voice/">回収の事例</a></li>                      
    </ul>
    </li>
    <li class="gnavi__list"><a href="https://porte-co.jp/price/">料金案内</a></li>
    <li class="gnavi__list">
    <a href="/">不用品回収について ▶︎</a>
    <ul class="dropdown__lists">
    <li class="dropdown__list"><a href="https://porte-co.jp/collection/">回収できる品目</a></li>
    <li class="dropdown__list"><a href="https://porte-co.jp/voc/">お客様の声</a></li>                   
    </ul>
    </li>
    <li class="gnavi__list"><a href="https://porte-co.jp/media/">お役立ちコラム</a></li>
    <li class="gnavi__list"><a href="https://porte-co.jp/allarea">対応エリア</a></li>
    <li class="gnavi__list">
    <a href="/">会社について ▶︎</a>
    <ul class="dropdown__lists">
    <li class="dropdown__list"><a href="https://porte-co.jp/company/">会社概要</a></li>
    <li class="dropdown__list"><a href="https://porte-co.jp/content_policy/">運営ポリシー</a></li>              
    </ul>
    </li>
    </ul>
    </div>
    </header>
  );
}

export default Header;
